<template>
  <section class="meep-table">
    <v-data-table
      :calculate-widths="true"
      :data-app="true"
      :headers="headers"
      :height="height"
      :hide-default-footer="true"
      :item-class="() => 'meep-table-row'"
      :items="items"
      :sort-by.sync="sortBy"
      class="elevation-1"
      fixed-header
    >
      <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
        <div :key="h.key" class="meep-table-header">
          <component
            :is="header.component"
            v-if="header.component"
            :class="header.iconClass"
            class="app-icon-table"
          />

          <span v-else>{{ header.text }}</span>
          <md-tooltip md-direction="bot" v-if="header.tooltip">{{
            header.tooltip
          }}</md-tooltip>
        </div>
      </template>

      <template
        v-for="(button, key) in buttons"
        v-slot:[`item.${[button.value]}`]="{ item }"
      >
        <md-button
          :key="key"
          :disabled="button.readOnly(item)"
          class="md-icon-button"
          @click.stop="goTo(item, button)"
        >
          <MeepIconDelete v-if="button.value === 'delete'" class="app-icon" />
        </md-button>
      </template>

      <template
        v-for="(checkbox, key) in checkboxes"
        v-slot:[`item.${[checkbox.value]}`]="{ item }"
      >
        <v-simple-checkbox
          :key="key"
          v-model="item[checkbox.value]"
          :disabled="checkbox.readOnly(item)"
          off-icon="mdi-checkbox-blank-circle-outline"
          on-icon="mdi-checkbox-marked-circle"
        />
      </template>

      <template
        v-for="(format, key) in formats"
        v-slot:[`item.${[format.value]}`]="{ item }"
      >
        <p :key="key">{{ format.format(item[format.value]) }}</p>
      </template>
    </v-data-table>
  </section>
</template>

<script>
import MeepIconDelete from "./icons/MeepIconDelete.vue";
import MeepIconRestore from "./icons/MeepIconRestore.vue";

export default {
  name: "MeepTable",

  components: {
    MeepIconDelete,
    MeepIconRestore,
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    listParameters: {
      type: Array,
      default: () => [],
    },
    height: {
      type: String,
      default: "",
    },
    sortBy: {
      type: String,
      default: "",
    },
  },

  computed: {
    headers() {
      return this.listParameters.map(param => {
        if (param.icon) {
          return {
            text: param.name,
            value: param.key,
            component: param.component,
            sortable: false,
            iconClass: param.iconClass,
            tooltip: param.tooltip,
          };
        }
        if (param.button) {
          return {
            sortable: false,
            text: param.name,
            value: param.key,
          };
        }
        return {
          text: param.name,
          value: param.key,
        };
      });
    },

    checkboxes() {
      return this.listParameters
        .filter(item => item.checkbox)
        .map(param => {
          return {
            text: param.name,
            value: param.key,
            readOnly: param.readOnly ? param.readOnly : false,
          };
        });
    },

    formats() {
      return this.listParameters
        .filter(item => item.format)
        .map(param => ({
          text: param.name,
          value: param.key,
          format: param.format,
        }));
    },

    buttons() {
      return this.listParameters
        .filter(item => item.button)
        .map(param => ({
          text: param.name,
          value: param.key,
          clickable: param.button,
          readOnly: param.readOnly ? param.readOnly : false,
        }));
    },
  },

  methods: {
    async goTo(item, button) {
      try {
        // Current item is clickable;
        if (button && button.clickable) {
          this.$emit("item-click", {
            item,
            button,
          });
          return;
        }

        // Do nothing if link not presented
        if (!this.link) {
          return;
        }

        // Go to item
        await this.$router.push(this.link + item.id);
      } catch (err) {
        console.log("Error goTo", err);
      }
    },
  },
};
</script>

<style lang="scss">
@import "../styles/_variable.scss";

.meep-table {
  display: block;
  max-height: 200px;
  margin-bottom: 50px;

  .v-data-table {
    background: transparent !important;
    position: relative;

    .v-data-table-header {
      height: 31px;
      @include for-lg {
        height: 62px;
      }

      th {
        background-color: var(--bg-third, var(--bg-alternate)) !important;
        font-family: var(--font-bold);
        font-size: 20px !important;
        color: var(--text-primary) !important;
      }

      th:first-child {
        border-top-left-radius: 11px;
        border-bottom-left-radius: 11px;
        padding-left: toRem(30);
        @include for-lg {
          padding-left: toRem(50);
        }
      }

      th:last-child {
        border-top-right-radius: 11px;
        border-bottom-right-radius: 11px;
      }

      .v-data-table-header__icon {
        color: var(--text-primary) !important;
      }
    }

    tbody:before {
      height: 25px;
      width: 100%;
      content: "";
      display: block;
    }

    tbody {
      .meep-table-row:first-child {
        border-top-left-radius: 10px;

        td:first-child {
          border-top-left-radius: 11px;
        }

        td:last-child {
          border-top-right-radius: 11px;
        }
      }
    }

    .meep-table-row {
      height: 38px;
      @include for-lg {
        height: 94px;
      }

      td {
        border-bottom: 1px solid var(--bg-secondary) !important;
        background-color: var(--bg-alternate);
        font-family: var(--font);
        font-size: toRem(11);
        @include for-lg {
          font-size: toRem(20);
        }

        .v-icon {
          font-size: 20px;
          color: var(--text-inverted);
          @include for-lg {
            font-size: 30px;
          }
        }

        &:first-child {
          padding-left: toRem(35);
          @include for-lg {
            padding-left: toRem(50);
          }
        }

        .md-button {
          &.md-icon-button {
            z-index: 0;
            width: 30px;
            min-width: 30px;
            height: 30px;
            min-height: 30px;

            .md-disabled {
              .app-icon {
                path {
                  fill: rgba(0, 0, 0, 0.38);
                }
              }
            }
          }

          display: flex;
          justify-content: center;
          align-items: center;

          .md-button-content {
            width: 11px;
            height: 15px;
            @include for-lg {
              height: 30px;
              width: 30px;
            }
            z-index: 0;
          }

          .app-icon {
            width: 11px;
            height: 15px;
            @include for-lg {
              height: 28px;
              width: 28px;
            }
          }
        }
      }

      &:hover {
        td,
        .md-table-cell.md-primary--inverted {
          background-color: var(--bg-primary);
          color: var(--text-primary);

          .app-icon {
            path {
              fill: var(--text-primary);
            }
          }
        }
      }
    }
  }

  &-header {
    display: inline;
  }

  .text-start {
    text-align: start;
  }

  .text-center {
    text-align: center;
  }
}

.app-gc {
  .meep-table {
    .v-data-table {
      .meep-table-row {
        td {
          border: none !important;
          background-color: var(--text-alternate);
        }

        &:hover td,
        .md-table-cell.md-primary--inverted {
          background-color: var(--bg-primary);
          color: var(--text-primary);

          .app-icon {
            path {
              fill: var(--text-primary);
            }
          }
        }
      }

      .md-selected {
        .md-table-cell {
          background-color: var(--bg-secondary);
          color: var(--text-primary);

          svg {
            path {
              fill: var(--text-primary);
            }
          }
        }

        .md-checkbox-container {
          background-color: var(--bg-third);
          border-color: var(--bg-third);
        }
      }
    }
  }
}
</style>
