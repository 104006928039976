<template>
  <div :class="companyViewClass" class="company-view">
    <Banner>
      <div class="banner-content">
        <MeepIconSettings class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.setting") }}</span>
      </div>
    </Banner>
    <div class="company-view-content page-layout">
      <LoadingCard v-if="isLoading" />

      <div v-else class="company-view-content-columns">
        <div class="company-view-content-first-column">
          <!-- ## Les informations de l'entreprise ## -->
          <md-card class="meep-form">
            <PageHeader
              :has-actions="false"
              :has-back="true"
              :has-search="false"
              :title="$t('information-company.title')"
              @back="goBack"
            />
            <!-- le formulaire de création -->
            <md-card-content>
              <div class="meep-form-column meep-form-single-column">
                <!-- Nom -->
                <div class="meep-input">
                  <md-field :class="{ 'md-invalid': errors.has('name') }">
                    <label>{{ $t("create-company.name") }}</label>

                    <md-input
                      v-model="company.name"
                      v-validate="{
                        required: true,
                        regex: /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _ & . -]*)$/,
                      }"
                      :disabled="!isAdmin"
                      name="name"
                      type="text"
                    >
                    </md-input>

                    <span v-show="errors.has('name')" class="md-error">
                      {{ errors.first("name") }}
                    </span>
                  </md-field>
                </div>
                <!-- Numéro de SIREN -->
                <div class="meep-input">
                  <md-field :class="{ 'md-invalid': errors.has('siren') }">
                    <label>{{ $t("create-company.siren") }}</label>

                    <md-input
                      v-model="company.siren"
                      v-validate="'required|numeric|min:9|max:9'"
                      :disabled="!isAdmin"
                      name="siren"
                      type="text"
                    >
                    </md-input>

                    <span v-show="errors.has('siren')" class="md-error">
                      {{ errors.first("siren") }}
                    </span>
                  </md-field>
                </div>

                <!-- Nom et prénom du dirigeant -->
                <div class="meep-input">
                  <md-field>
                    <label>{{ $t("companies.view.dirigeant.label") }}</label>

                    <md-input
                      v-model="company.dirigeant"
                      :disabled="!isAdmin"
                      name="firstName"
                      readonly
                      type="text"
                    >
                    </md-input>
                  </md-field>
                </div>

                <!-- Email -->
                <div class="meep-input">
                  <md-field :class="{ 'md-invalid': errors.has('email') }">
                    <label>{{ $t("create-company.email") }}</label>

                    <md-input
                      v-model="company.email"
                      v-validate="{
                        email: true,
                      }"
                      :disabled="!isAdmin"
                      name="email"
                      type="email"
                    >
                    </md-input>

                    <span v-show="errors.has('email')" class="md-error">
                      {{ errors.first("email") }}
                    </span>
                  </md-field>
                </div>

                <!-- Email Application -->
                <div class="meep-input">
                  <md-field>
                    <label>{{ $t("create-company.email-applicaiton") }}</label>

                    <md-input
                      v-model="company.application_email"
                      :disabled="!isAdmin"
                      name="email"
                      type="email"
                    >
                    </md-input>
                  </md-field>
                </div>

                <!-- Numéro de téléphone -->
                <div class="meep-input">
                  <md-field :class="{ 'md-invalid': errors.has('tel') }">
                    <label>{{ $t("create-company.tel") }}</label>

                    <md-input
                      v-model="company.tel"
                      v-validate="'telephone'"
                      :disabled="!isAdmin"
                      name="tel"
                      type="tel"
                    >
                    </md-input>

                    <span v-show="errors.has('tel')" class="md-error">
                      {{ errors.first("tel") }}
                    </span>
                  </md-field>
                </div>

                <!-- Site Web -->
                <div class="meep-input">
                  <md-field :class="{ 'md-invalid': errors.has('url') }">
                    <label>{{ $t("create-company.url") }}</label>

                    <md-input
                      v-model="company.url"
                      v-validate="'url'"
                      :disabled="!isAdmin"
                      name="url"
                      type="url"
                    >
                    </md-input>

                    <span v-show="errors.has('url')" class="md-error">
                      {{ errors.first("url") }}
                    </span>
                  </md-field>
                </div>
                <!-- Numéro et voie -->
                <div class="meep-input">
                  <md-field>
                    <label>Adresse</label>

                    <md-input
                      v-model="company.adresse"
                      :disabled="!isAdmin"
                      name="adresse"
                      type="text"
                    >
                    </md-input>
                  </md-field>
                </div>

                <!-- Ville -->
                <div class="meep-input">
                  <md-field :class="{ 'md-invalid': errors.has('ville') }">
                    <label>Ville</label>

                    <md-input
                      v-model="company.ville"
                      v-validate="'alpha_spaces'"
                      :disabled="!isAdmin"
                      name="ville"
                      type="ville"
                    >
                    </md-input>

                    <span v-show="errors.has('ville')" class="md-error">
                      {{ errors.first("ville") }}
                    </span>
                  </md-field>
                </div>

                <!-- Code postal -->
                <div class="meep-input">
                  <md-field :class="{ 'md-invalid': errors.has('codepostal') }">
                    <label>Code postal</label>

                    <md-input
                      v-model="company.codepostal"
                      v-validate="'numeric|min:5|max:5'"
                      :disabled="!isAdmin"
                      name="codepostal"
                      type="text"
                    >
                    </md-input>

                    <span v-show="errors.has('codepostal')" class="md-error">
                      {{ errors.first("codepostal") }}
                    </span>
                  </md-field>
                </div>

                <div v-if="hasEmailSynchro" class="meep-input">
                  <md-field :class="{ 'md-invalid': errors.has('codepostal') }">
                    <label
                      >E-mail pour synchroniser le cloud avec un autre service
                    </label>

                    <md-input
                      v-model="company.cloud_synchronize_email"
                      :disabled="!isAdmin"
                      name="cloud_synchronize_email"
                      type="text"
                    >
                    </md-input>
                  </md-field>
                </div>

                <div v-if="isAdmin" class="md-layout md-alignment-center-right">
                  <md-button
                    class="md-primary--inverted"
                    @click="onInfosCancel"
                  >
                    {{ $t("groupes.rename-cancel") }}
                  </md-button>
                  <md-button class="md-primary" @click="handleClick('save')">
                    {{ $t("groupes.rename-confirm") }}
                  </md-button>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </div>

        <div class="company-view-content-second-column">
          <!-- ## Les groupes ## -->
          <md-card v-if="!isClient" class="company-view__card">
            <PageHeader :has-actions="true" title="Groupes">
              <md-button
                v-if="!isClient"
                class="md-primary header-action header-action-icon"
                @click="openSelectItemModal('groupes')"
              >
                <MeepIconActionAdd class="app-icon-stroke-inverted" />
              </md-button>
            </PageHeader>
            <!-- Le titre -->
            <template>
              <md-list v-if="newGroupes.length" class="meep-select-list">
                <md-list-item v-for="groupe in newGroupes" :key="groupe.id">
                  <div class="md-list-item-text users-create__list-item">
                    {{ groupe.name }}
                  </div>

                  <md-button
                    class="md-icon-button md-list-action"
                    @click="removeGroupe(groupe.id)"
                  >
                    <md-icon v-if="!isClient" class="md-primary">
                      cancel
                    </md-icon>
                  </md-button>
                </md-list-item>
              </md-list>
              <md-empty-state
                v-else
                :md-description="$t('companies.no-group-text')"
              />
            </template>
            <div class="md-layout md-alignment-center-right">
              <md-button
                v-if="!isClient"
                class="md-primary--inverted"
                @click="newGroupes = oldCompany.groupes"
              >
                {{ $t("groupes.rename-cancel") }}
              </md-button>
              <md-button
                v-if="!isClient"
                class="md-primary"
                @click="handleClick('groupesSave')"
              >
                {{ $t("groupes.rename-confirm") }}
              </md-button>
            </div>
          </md-card>

          <!-- ## Les collaborateurs ## -->
          <md-card v-if="!isClient" class="company-view__card md-layout-item">
            <PageHeader :has-actions="true" title="Collaborateurs">
              <md-button
                v-if="isAdmin"
                class="md-primary header-action header-action-icon"
                @click="openSelectItemModal('collab')"
              >
                <MeepIconActionAdd class="app-icon-stroke-inverted" />
              </md-button>
            </PageHeader>
            <md-card-content>
              <!-- La liste des collaborateurs -->
              <MeepTable
                v-if="newCollabs.length !== 0"
                :items="newCollabs"
                :listParameters="collabsParameters"
                height="226"
                sortBy="firstname"
                @item-click="onCollabClick"
              />

              <!-- À afficher lorqu'il n'y a personne -->
              <md-empty-state
                v-else
                :md-description="$t('companies.no-collab-text')"
              />
            </md-card-content>
            <div v-if="isAdmin" class="md-layout md-alignment-center-right">
              <md-button
                class="md-primary--inverted"
                @click="newCollabs = oldCompany.collaborators"
              >
                {{ $t("groupes.rename-cancel") }}
              </md-button>
              <md-button class="md-primary" @click="handleClick('collabsSave')">
                {{ $t("groupes.rename-confirm") }}
              </md-button>
            </div>
          </md-card>

          <!-- ## Les utilisateurs ## -->
          <md-card
            class="company-view__card md-layout-item"
            :class="{ 'company-view__card-client': isClient }"
          >
            <PageHeader :has-actions="true" title="Utilisateurs">
              <md-button
                v-if="isAdmin"
                class="md-primary header-action header-action-icon"
                @click="openSelectItemModal('users')"
              >
                <MeepIconActionAdd class="app-icon-stroke-inverted" />
              </md-button>
            </PageHeader>
            <md-card-content>
              <!-- La liste des collaborateurs -->

              <MeepTable
                v-if="newUsers.length"
                :items="newUsers"
                :listParameters="usersParameters"
                height="226"
                sortBy="firstname"
                @item-click="onUserClick"
              />

              <md-empty-state
                v-else
                :md-description="$t('companies.no-client-text')"
              />
            </md-card-content>
            <div class="md-layout md-alignment-center-right">
              <md-button
                class="md-primary--inverted"
                @click="newUsers = oldCompany.users"
              >
                {{ $t("groupes.rename-cancel") }}
              </md-button>
              <md-button class="md-primary" @click="handleClick('usersSave')">
                {{ $t("groupes.rename-confirm") }}
              </md-button>
            </div>
          </md-card>
        </div>

        <div v-if="!isClient" class="company-view-content-third-column">
          <md-button
            class="md-primary header-action header-action-icon"
            @click="handleClick('delete')"
          >
            <MeepIconActionDelete class="app-icon" />
          </md-button>
        </div>
      </div>
    </div>

    <select-items-modal
      v-if="isSelectModalOpen"
      :items-list="catalog"
      :selected-list="selectedList"
      :text="selectCompaniesModalText"
      @close="isSelectModalOpen = false"
      @saved="onSelectModal"
    >
      <template #icon>
        <MeepIconSettingGroupes
          v-if="selectModalContext === 'groupes'"
          class="app-icon-color"
        />
        <MeepIconSettingUsers v-else class="app-icon-color" />
      </template>
    </select-items-modal>

    <confirm-action-modal
      v-if="isConfirmGlobalCancelModalOpen"
      :object-to-act-upon="{}"
      :text="confirmGlobalCancelModalText"
      @close="closeConfirmModal"
      @confirm="onConfirmModal"
    />
  </div>
</template>

<script>
/* Modèles */
import companiesModel from "../../model/companies";
import groupesModel from "../../model/groupes";
import { mapGetters } from "vuex";
import PageHeader from "@/components/PageHeader";
import LoadingCard from "@/components/LoadingCard";
import Banner from "@/components/Banner";
import MeepIconSettings from "@/components/icons/MeepIconSettings.vue";
import MeepIconActionAdd from "@/components/icons/MeepIconActionAdd.vue";
import MeepIconCloud from "@/components/icons/MeepIconCloud.vue";
import MeepIconCompanyClick from "@/components/icons/MeepIconCompanyClick.vue";
import MeepIconCompanyChat from "@/components/icons/MeepIconCompanyChat.vue";
import MeepIconCompanyInformation from "@/components/icons/MeepIconCompanyInformation.vue";
import MeepIconActionDelete from "@/components/icons/MeepIconActionDelete.vue";
import MeepIconSettingGroupes from "@/components/icons/MeepIconSettingGroupes.vue";
import MeepIconSettingUsers from "@/components/icons/MeepIconSettingUsers.vue";
import confirmActionModal from "@/components/modal/confirm-action";
import ForceLeaveMixin from "@/mixins/ForceLeaveMixin";

import MeepTable from "@/components/MeepTable.vue";
import selectItemsModal from "@/components/modal/select-items";
import usersModel from "@/model/users";
import { compareObjects, filterTwoArray } from "@/services/util";
import { USERS, MODALS } from "@/constants";
import _ from "lodash";

const COMPANY_ACTION = {
  GROUPES_SAVE: "groupesSave",
  COLLABS_SAVE: "collabsSave",
  USERS_SAVE: "usersSave",
};

export default {
  name: "ViewCompany",
  components: {
    LoadingCard,
    PageHeader,
    Banner,
    MeepIconSettings,
    MeepIconActionAdd,
    MeepIconActionDelete,
    MeepIconSettingGroupes,
    MeepIconSettingUsers,
    MeepTable,
    selectItemsModal,
    confirmActionModal,
  },

  mixins: [ForceLeaveMixin],

  data() {
    return {
      isLoading: false,
      error: false,
      company: {},
      oldCompany: {},
      currentTab: "tab-info",
      listGroupes: [],
      listCollab: [],
      listUser: [],
      selectModalContext: "",
      nextPath: "",

      newUsers: [],
      newCollabs: [],
      newGroupes: [],

      usersParameters: [
        {
          name: "Prénom",
          key: "firstname",
        },
        {
          name: "Nom",
          key: "lastname",
        },
        {
          name: "Statut",
          key: "scope",
          format: this.$$filters.formatScope,
        },
        {
          name: "mouse",
          key: "has_web_access",
          checkbox: true,
          icon: true,
          iconClass: "app-icon-inverted",
          component: MeepIconCompanyClick,
          readOnly: item => {
            return item.scope !== USERS.EMPLOYEE && !this.isAdmin;
          },
          tooltip: this.$t("companies.clients.web-tooltip"),
        },
        {
          name: "chat",
          key: "can_speak_in_chat",
          checkbox: true,
          icon: true,
          iconClass: "app-icon-inverted",
          component: MeepIconCompanyChat,
          readOnly: item => {
            return item.scope !== USERS.EMPLOYEE && !this.isAdmin;
          },
          tooltip: this.$t("companies.users.can_speak_in_chat-tooltip"),
        },
        {
          name: "cloud",
          key: "can_write_in_cloud",
          checkbox: true,
          icon: true,
          iconClass: "app-icon-stroke-inverted",
          component: MeepIconCloud,
          readOnly: item => {
            return item.scope !== USERS.EMPLOYEE && !this.isAdmin;
          },
          tooltip: this.$t("companies.users.can_write_in_cloud-tooltip"),
        },
        {
          name: "",
          key: "delete",
          button: true,
          iconClass: "app-icon-inverted",
          readOnly: item => {
            return item.scope !== USERS.EMPLOYEE && !this.isAdmin;
          },
        },
      ],

      collabsParameters: [
        {
          name: "Prénom",
          key: "firstname",
        },
        {
          name: "Nom",
          key: "lastname",
        },
        {
          name: "cloud",
          key: "can_write_in_cloud",
          checkbox: true,
          icon: true,
          iconClass: "app-icon-stroke-inverted",
          component: MeepIconCloud,
          readOnly: () => {
            return !this.isAdmin;
          },
          tooltip: this.$t("companies.collabs.cloud-tooltip"),
        },
        {
          name: "info",
          key: "can_modify_infos",
          icon: true,
          checkbox: true,
          iconClass: "app-icon-inverted",
          component: MeepIconCompanyInformation,
          readOnly: () => {
            return !this.isAdmin;
          },
          tooltip: this.$t("companies.collabs.assignment-tooltip"),
        },
        {
          name: "mouse",
          key: "can_speak_in_chat",
          checkbox: true,
          icon: true,
          iconClass: "app-icon-inverted",
          component: MeepIconCompanyChat,
          readOnly: () => {
            return !this.isAdmin;
          },
          tooltip: this.$t("companies.collabs.chat-tooltip"),
        },
        {
          name: "",
          key: "delete",
          button: true,
          iconClass: "app-icon-inverted",
          readOnly: item => {
            return item.scope !== USERS.EMPLOYEE && !this.isAdmin;
          },
        },
      ],

      isSelectModalOpen: false,
      selectCompaniesModalText: {
        header: "Selectionner les entreprises",
      },

      confirmationContext: "",
      isConfirmGlobalCancelModalOpen: false,
    };
  },

  computed: {
    ...mapGetters([
      "isAdmin",
      "isCollab",
      "isClient",
      "isCE",
      "isLCM",
      "isJEP",
    ]),

    companyViewClass() {
      return {
        "company-view--jep": this.isJEP,
        "company-view--ce": this.isCE,
        "company-view--lcm": this.isLCM,
      };
    },

    isInfosModified() {
      if (this.company.name !== this.oldCompany.name) return true;
      if (this.company.siren !== this.oldCompany.siren) return true;
      if (this.company.tel !== this.oldCompany.tel) return true;
      if (this.company.adresse !== this.oldCompany.adresse) return true;
      if (this.company.codepostal !== this.oldCompany.codepostal) return true;
      if (this.company.ville !== this.oldCompany.ville) return true;
      if (this.company.email !== this.oldCompany.email) return true;
      if (this.company.url !== this.oldCompany.url) return true;
      return (
        this.company.cloud_synchronize_email !==
        this.oldCompany.cloud_synchronize_email
      );
    },

    isGroupesModified() {
      return !compareObjects(this.company.groupes, this.newGroupes);
    },

    isCollabsModified() {
      return !compareObjects(this.company.collaborators, this.newCollabs);
    },

    isUsersModified() {
      return !compareObjects(this.company.users, this.newUsers);
    },

    isAnyModified() {
      return (
        this.isInfosModified ||
        this.isGroupesModified ||
        this.isCollabsModified ||
        this.isUsersModified
      );
    },

    hasCloud() {
      return this.$store.state.self.organization.has_cloud;
    },

    hasEmailSynchro() {
      return this.$store.state.self.organization.has_email_synchro;
    },

    canWrite() {
      /* L'admin est tout puissant et le client ne peut que voir que ses entreprises */
      if (this.isAdmin || this.isClient) {
        return true;
      }

      if (this.isCollab && this.company.collaborators) {
        const rights = this.company.collaborators.find(collab => {
          return collab.id === this.$store.state.self.id;
        });

        return rights !== undefined && rights.can_modify_infos;
      }

      return false;
    },

    canViewInfo() {
      return this.isCollab || this.isAdmin;
    },

    catalog() {
      switch (this.selectModalContext) {
        case "groupes":
          return this.listGroupes;
        case "collab":
          return this.listCollab;
        default:
          return this.listUser;
      }
    },

    selectedList() {
      switch (this.selectModalContext) {
        case "groupes":
          return this.newGroupes;
        case "collab":
          return this.newCollabs;
        default:
          return this.newUsers;
      }
    },

    confirmGlobalCancelModalText() {
      if (this.confirmationContext === MODALS.UNSAVE) {
        return {
          header: this.$t("companies.view.unsaved-modal.header"),
          body: () => {
            return this.$t("companies.view.unsaved-modal.body");
          },
          question: this.$t("companies.view.unsaved-modal.question"),
        };
      } else if (
        this.confirmationContext === MODALS.SAVE ||
        this.confirmationContext === COMPANY_ACTION.GROUPES_SAVE ||
        this.confirmationContext === COMPANY_ACTION.COLLABS_SAVE ||
        this.confirmationContext === COMPANY_ACTION.USERS_SAVE
      ) {
        return {
          header: this.$t("companies.view.update-modal.header"),
          body: () => {
            return this.$t("companies.view.update-modal.body");
          },
          question: this.$t("companies.view.update-modal.question"),
        };
      } else
        return {
          header: this.$t("companies.view.delete-modal.header"),
          body: () => {
            return this.$t("companies.view.delete-modal.body");
          },
          question: this.$t("companies.view.delete-modal.question"),
        };
    },
  },

  watch: {
    $route() {
      this.currentTab = "";

      this.loadCompany();
    },
  },

  mounted() {
    this.loadCompany();
  },

  methods: {
    changeTab(tabId) {
      this.currentTab = tabId;
    },

    goBack() {
      window.history.back();
    },

    showTab(tabId) {
      return !this.isLoading && !this.error && tabId === this.currentTab;
    },

    async loadCompany() {
      try {
        this.isLoading = true;
        this.error = false;
        this.oldCompany = await companiesModel.get(this.$route.params.id);
        this.company = _.cloneDeep(this.oldCompany);
        this.newUsers = _.cloneDeep(this.company.users);
        this.newCollabs = _.cloneDeep(this.company.collaborators);
        this.newGroupes = _.cloneDeep(this.company.groupes);

        this.listUser = await usersModel.getOthers(this.$route.params.id);

        if (!this.isClient) {
          this.listCollab = await usersModel.getCollabs();
          this.listGroupes = await groupesModel.getAll();
        }
        this.listUser = this.listUser.map(item => {
          item.name = item.firstname + " " + item.lastname;
          item.has_web_access = true;
          return item;
        });

        this.listCollab = this.listCollab.map(item => {
          item.name = item.firstname + " " + item.lastname;
          return item;
        });

        this.error = false;
        this.isLoading = false;
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });

        this.error = true;

        this.isLoading = false;
      }
    },

    openSelectItemModal(context) {
      this.selectModalContext = context;
      this.isSelectModalOpen = true;
      switch (context) {
        case "groupes":
          this.selectCompaniesModalText = {
            header: "Sélectionner les groupes",
          };
          break;
        case "collab":
          this.selectCompaniesModalText = {
            header: "Sélectionner les collaborateurs",
          };
          break;
        default:
          this.selectCompaniesModalText = {
            header: "Sélectionner les utilisateurs",
          };
      }
    },

    onSelectModal(list) {
      switch (this.selectModalContext) {
        case "groupes":
          this.newGroupes = list;
          break;
        case "collab":
          this.newCollabs = list;
          break;
        default:
          this.newUsers = list;
      }
    },

    removeGroupe(id) {
      this.newGroupes = this.newGroupes.filter(item => item.id !== id);
    },

    async onGroupesSave() {
      try {
        let oldGroupes = filterTwoArray(this.company.groupes, this.newGroupes);

        let newGroupes = filterTwoArray(this.newGroupes, this.company.groupes);

        if (oldGroupes && oldGroupes.length) {
          await Promise.all(
            oldGroupes.map(groupe =>
              groupesModel.removeCompany(groupe.id, this.company.id)
            )
          );
        }

        if (newGroupes.length) {
          await Promise.all(
            newGroupes.map(groupe =>
              groupesModel.addCompany(groupe.id, this.company.id)
            )
          );
        }

        this.company.groupes = _.cloneDeep(this.newGroupes);

        this.$toasted.global.AppSucces({
          message: "Les groupes ont bien été mis à jour",
        });
      } catch (error) {
        console.log(error);
        this.$toasted.global.AppError({
          message: error.msg,
        });
      }
    },

    async onCollabsSave() {
      try {
        const oldCollabs = filterTwoArray(
          this.company.collaborators,
          this.newCollabs
        );

        if (oldCollabs && oldCollabs.length) {
          await Promise.all(
            oldCollabs.map(collab =>
              usersModel.removeCollabRights(collab.id, this.company.id)
            )
          );
        }

        if (this.newCollabs.length) {
          await Promise.all(
            this.newCollabs.map(collab =>
              usersModel.updateCollabRights(collab, this.company.id)
            )
          );
        }

        this.company.collaborators = _.cloneDeep(this.newCollabs);

        this.$toasted.global.AppSucces({
          message: "Les droits des collaborateurs ont bien été mis à jour",
        });
      } catch (error) {
        console.log(error);
        this.$toasted.global.AppError({
          message: error.msg,
        });
      }
    },

    async onUsersSave() {
      try {
        const oldUsers = filterTwoArray(this.company.users, this.newUsers);

        if (oldUsers && oldUsers.length) {
          await Promise.all(
            oldUsers.map(user => {
              if (this.isClient) {
                if (user.scope === USERS.EMPLOYEE) {
                  return usersModel.removeCompany(user.id, this.company.id);
                }
                return;
              }
              return usersModel.removeCompany(user.id, this.company.id);
            })
          );
        }

        await Promise.all(
          this.newUsers.map(user => {
            if (this.isClient) {
              if (user.scope === USERS.EMPLOYEE) {
                return usersModel.addCompany(
                  user.id,
                  this.company.id,
                  user.has_web_access,
                  user.can_write_in_cloud,
                  user.can_modify_infos,
                  user.can_speak_in_chat
                );
              }
              return;
            }
            return usersModel.addCompany(
              user.id,
              this.company.id,
              user.has_web_access,
              user.can_write_in_cloud,
              user.can_modify_infos,
              user.can_speak_in_chat
            );
          })
        );

        this.company.users = _.cloneDeep(this.newUsers);

        this.$toasted.global.AppSucces({
          message: "Les utilisateurs ont bien été mis à jour",
        });
      } catch (error) {
        console.log(error);
        this.$toasted.global.AppError({
          message: error.msg,
        });
      }
    },

    async onInfosSave() {
      try {
        const result = await this.$validator.validateAll();

        if (!result) {
          this.$toasted.global.AppError({
            message: "Vous devez remplir les champs manquants",
          });

          return;
        }

        await companiesModel.update(this.company);

        this.oldCompany = _.cloneDeep(this.company);

        this.$toasted.global.AppSucces({
          message: "Les informations de l'entreprise ont bien été mise à jour",
        });
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });
      }
    },

    onInfosCancel() {
      this.company = _.cloneDeep(this.oldCompany);
    },

    async onTrash() {
      try {
        await companiesModel.putInTrash(this.company.id);
        this.$toasted.global.AppSucces({
          message:
            "L'entreprise '" + this.company.name + "' à bien été supprimée",
        });
        this.forceLeave = true;
        this.$router.push("/dashboard/setting/companies/");
      } catch (error) {
        this.$toasted.global.AppError({
          message: error.msg,
        });
      }
    },

    openConfirmModal() {
      this.isConfirmGlobalCancelModalOpen = true;
    },

    closeConfirmModal() {
      this.isConfirmGlobalCancelModalOpen = false;
    },

    handleClick(text) {
      switch (text) {
        case MODALS.DELETE:
          this.confirmationContext = MODALS.DELETE;
          this.openConfirmModal();
          break;
        case MODALS.SAVE:
          if (this.isInfosModified) {
            this.confirmationContext = MODALS.SAVE;
            this.openConfirmModal();
          }
          break;
        case COMPANY_ACTION.GROUPES_SAVE:
          if (this.isGroupesModified) {
            this.confirmationContext = COMPANY_ACTION.GROUPES_SAVE;
            this.openConfirmModal();
          }
          break;
        case COMPANY_ACTION.COLLABS_SAVE:
          if (this.isCollabsModified) {
            this.confirmationContext = COMPANY_ACTION.COLLABS_SAVE;
            this.openConfirmModal();
          }
          break;
        case COMPANY_ACTION.USERS_SAVE:
          if (this.isUsersModified) {
            this.confirmationContext = COMPANY_ACTION.USERS_SAVE;
            this.openConfirmModal();
            break;
          }
      }
    },

    onConfirmModal() {
      switch (this.confirmationContext) {
        case MODALS.SAVE:
          this.onInfosSave();
          break;
        case MODALS.DELETE:
          this.onTrash();
          break;
        case COMPANY_ACTION.GROUPES_SAVE:
          this.onGroupesSave();
          break;
        case COMPANY_ACTION.COLLABS_SAVE:
          this.onCollabsSave();
          break;
        case COMPANY_ACTION.USERS_SAVE:
          this.onUsersSave();
          break;
        default:
          this.forceRouteLeave();
          break;
      }
    },

    onUserClick(item) {
      this.newUsers = this.newUsers.filter(
        checkItem => checkItem.id !== item.item.id
      );
    },

    onCollabClick(item) {
      this.newCollabs = this.newCollabs.filter(
        checkItem => checkItem.id !== item.item.id
      );
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";

.company-view {
  // CSS company-view in CE web
  &.company-view--ce {
    .company-view-content {
      .company-view-content-second-column {
        .company-view__card {
          .md-card-content {
            tbody {
              .meep-table-row {
                td {
                  background-color: var(--text-primary);
                }

                &:hover td {
                  background-color: var(--bg-alternate);
                  color: var(--text-primary);
                }
              }
            }
          }
        }
      }
    }
  }

  &.company-view--jep {
    .company-view-content {
      .company-view-content-second-column {
        .company-view__card {
          .md-card-content {
            tbody {
              .meep-table-row {
                td {
                  background-color: var(--text-primary);
                }

                &:hover td {
                  background-color: var(--bg-alternate);
                  color: var(--text-primary);
                }
              }
            }
          }
        }
      }
    }
  }

  &__card {
    padding: 45px;
    margin: 0;
    widows: 100%;

    .md-alignment-center-right {
      .md-ripple {
        font-size: toRem(9);
        @include for-lg {
          font-size: toRem(14);
        }
      }
    }

    .page-header {
      &__item {
        padding: 0;

        .page-title {
          margin: 0 !important;
        }
      }
    }

    &-client {
      max-height: 490px;
    }
  }

  .md-subheader {
    min-height: 24px;
    margin-top: 4px;
  }

  .md-layout-item {
    margin-top: 20px;
  }

  .md-layout .md-button .md-ripple {
    padding: 0px 30px;
  }

  &-content {
    margin-bottom: 36px;

    &-columns {
      display: flex;
      flex-direction: row;
      gap: 35px;
    }

    &-first-column,
    &-second-column {
      flex: 0 0 46%;
      display: flex;
      flex-direction: column;
      gap: 35px;
      @include for-lg {
        flex: 0 0 45%;
      }

      .md-card {
        margin-right: 0px;
        margin-left: 0px;
      }
    }

    .company-view-content-second-column {
      .md-card-content {
        padding: 0;
        //To overide component
        .v-icon.mdi-arrow-up {
          font-size: toRem(12) !important;
          @include for-lg {
            font-size: toRem(18) !important;
          }
        }
        .meep-table-header {
          font-size: toRem(11);
          @include for-lg {
            font-size: toRem(20);
          }
        }

        th {
          padding-right: 0;
        }

        .app-icon-table {
          width: 20px;
          height: 20px;
          @include for-lg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    &-third-column {
      .md-button.header-action-icon {
        min-width: unset;
        width: var(--header-icon-size-lg);
        height: var(--header-icon-size-lg);
        min-height: unset;
        @include for-lg {
          width: var(--header-icon-size-xl);
          height: var(--header-icon-size-xl);
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  #app {
    .company-view-content-columns {
      gap: 22px;
    }

    .company-view-content-second-column {
      gap: 22px;
    }
  }
}
</style>
