<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.614 20.614"><defs/><g id="Company-Information_svg__Groupe_987" data-name="Groupe 987" transform="translate(-1455.319 -688.279)"><path id="Company-Information_svg__Tracé_21" data-name="Tracé 21" d="M2451.128-888.843h-.712v-6.162h-2.158a.6.6 0 00-.607.6.6.6 0 00.607.6h.943v4.96h-.864a.6.6 0 00-.608.6.6.6 0 00.608.6h2.791a.6.6 0 00.607-.6.6.6 0 00-.607-.6" transform="translate(-984.068 1591.523)"/><path id="Company-Information_svg__Tracé_22" data-name="Tracé 22" d="M2449.893-898.76a1.086 1.086 0 011.092 1.08 1.087 1.087 0 01-1.092 1.081 1.087 1.087 0 01-1.091-1.081 1.086 1.086 0 011.091-1.08" transform="translate(-984.229 1592.047)"/><circle id="Company-Information_svg__Ellipse_6" data-name="Ellipse 6" cx="9.807" cy="9.807" r="9.807" transform="translate(1455.819 688.779)" fill="none" stroke="#fff" stroke-miterlimit="10"/></g></svg>
</template>

<script>
  export default {
    name : 'MeepIconCompanyInformation'
  };
</script>
